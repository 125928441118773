export const  enum_data = {
    STATICIP: "Static IP",
    CIDR: "CIDR",
    DOMAIN: "Domain",
    IPRANGE: "IP Range",
    WINDOWS: "Windows",
    VMWARE: "VMWare",
    LINUX: "Linux",
    MAC: "macOS",
    NETWORKDEVICE: "Network Device",
    FIREWALLOS: "Firewall OS",
    SNMPV1: "SNMP v1",
    SNMPV2: "SNMP v2",
    SNMPV3: "SNMP v3",
    AD: "AD",
    ASSETCREDENTIAL: "Asset Credential",
    FIREWALLCREDENTIAL: "Firewall Credential",
    FULLSCAN: "Full Scan",
    SNMPSCAN: "SNMP Scan",
    ADSCAN: "AD Scan",
    NETWORKSCAN: "Network Scan",
    EXTERNALSCAN: "External Scan",
    PIISCAN: "PII Scan",
    INITIATED: "Initiated",
    STARTED: "Started",
    INPROGRESS: "In-Progress",
    SUCCESS: "Success",
    COMPLETED: "Completed",
    FAILED: "Failed",
    Open: "Open",
    Approved: "Approved",
    Rejected: "Rejected",
    synscan: "Syn Scan",
    connectscan: "Connect Scan",
    xmasscan: "Xmas Scan",
    windows: "Windows",
    darwin: "macOS",
    linux: "Linux",
    application: "Application",
    service: "Service",
    ATTACKSURFACESCAN: "Attack Surface Mapper"
}