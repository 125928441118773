import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DynamicSettingsService {
  // Private
  private _settings: BehaviorSubject<any | null> = new BehaviorSubject(null);

  //Event Emitters
  public partnerRoles = new EventEmitter();
  public partnerDistributors = new EventEmitter();
  public partnerUsers = new EventEmitter();
  public cancelledPartners = new EventEmitter();
  public suspendPartners = new EventEmitter();
  public paidPartners = new EventEmitter();
  public planPartners = new EventEmitter();
  public trailPartners = new EventEmitter();
  public allPartners = new EventEmitter();
  public assetEmitter = new EventEmitter();
  public adEmitter = new EventEmitter();
  public scanProfileEmitter = new EventEmitter();
  public scanResultEmitter = new EventEmitter();
  public azureSyncEmitter = new EventEmitter();
  public microSyncEmitter = new EventEmitter();
  public asmEmitter = new EventEmitter();
  public complianceEmitter = new EventEmitter();
  public agentEmitter = new EventEmitter();
  public exConfigEmitter = new EventEmitter();
  public schedulerTabEmitter = new EventEmitter();
  public schedulerEmitter = new EventEmitter();
  public externalTabEmitter = new EventEmitter();
  public partnerTabEmitter = new EventEmitter();
  public appBaseEmitter = new EventEmitter();

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  /**
   * Get setting by id
   */
  getSettingsById(id: string): Observable<any> {
    return this._httpClient
      .get<any>("api/common/settings", { params: { id } })
      .pipe(
        map((setting) => {
          // Update the course
          this._settings.next(setting);

          // Return the course
          return setting;
        }),
        switchMap((setting) => {
          if (!setting) {
            return throwError("Could not found course with id of " + id + "!");
          }

          return of(setting);
        })
      );
  }
}
