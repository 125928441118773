import { BooleanInput } from "@angular/cdk/coercion";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { Router } from "@angular/router";
import { BaseRequestService } from "app/_services/base.service";
import { UserService } from "app/core/user/user.service";
import { Subject, takeUntil } from "rxjs";
import { CommonService } from "../../../_services/common.service";
import { LoaderService } from "app/_services/loader.service";
import { MyToastrService } from "app/_services/toastr.service";
import { MaterialModule } from "app/material.module";
import { ModalComponent } from "app/modules/shared/modal.component";
import { ModalService } from "app/_services/modal.service";
import { FuseScrollbarDirective } from "@fuse/directives/scrollbar";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    NgIf,
    NgFor,
    MatIconModule,
    NgClass,
    MatDividerModule,
    MaterialModule,
    ModalComponent,
    FuseScrollbarDirective,
  ],
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: any;
  payload: any;
  domainName: any;
  releaseNotes: any = {};
  buildInfo: any = {};
  Objectkeys = Object.keys;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    public _modalService: ModalService,
    private _baseService: BaseRequestService,
    private _userService: UserService,
    private cs: CommonService,
    private toast: MyToastrService,
    private _ls: LoaderService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    /* this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            }); */
    this._userService.userd$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: any) => {
        this.user = user;
        this._baseService.user.set(user);
        this._baseService.getWhiteLabelSetting();
        this.domainName = user["urn:zitadel:iam:user:resourceowner:name"];
        let s = Object.keys(user["urn:zitadel:iam:org:project:roles"]);
        let tenantid = Object.keys(
          user["urn:zitadel:iam:org:project:roles"][s[0]]
        )[0];
        this.payload = {
          sub: tenantid,
          email: user["email"],
          first_name: user["given_name"],
          last_name: user["family_name"],
          exp: Math.floor((Date.now() + 120 * 60 * 1000) / 1000),
        };
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(["/sign-out"]).then((r) => console.log(r));
  }

  viewUserManagementPage(): void {
    window.open("https://authprod.myconnectsecure.com/ui/console/");
  }
  viewAuthPage() {
    window.open("https://authprod.myconnectsecure.com/ui/console/users/me");
  }
  viewDashboardPage() {
    const token = this.cs.generateToken(this.payload);
    window.open(
      `https://dashboard.myconnectsecure.com/sso/jwt/callback?jwt=${token}&site_identifier=${this.domainName}`
    );
  }
  viewReportPage() {
    window.open(
      `https://reportcs.myconnectsecure.com/reporting/site/${this.domainName}`
    );
  }
  viewReleaseNotes() {
    this._ls.display(true);
    this._baseService
      .doRequest(`${this._baseService.configURL}/getReleaseNotes`, "get")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this._ls.display(false);
        if (res.status) {
          this.releaseNotes = res.data;
          this._modalService.open("releaseNotes");
          this._changeDetectorRef.markForCheck();
        }
      });
  }
  viewBuildInfo() {
    this._ls.display(true);
    this._baseService
      .doRequest(`${this._baseService.configURL}/agentBuildInfo`, "get")
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this._ls.display(false);
        if (res.status) {
          this.buildInfo = res.data;
          this._modalService.open("buildInformation");
          this._changeDetectorRef.markForCheck();
        }
      });
  }
}
