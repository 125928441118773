import { Route } from "@angular/router";
import { initialDataResolver } from "app/app.resolvers";
import { AuthGuard } from "app/core/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";

export const appRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "summary",
  },
  {
    path: 'auth/callback',
    redirectTo: 'summary'
  },

  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-in",
        loadChildren: () => import("app/modules/auth/sign-in/sign-in.routes"),
      },
      {
        path: "login",
        loadChildren: () =>
          import("app/modules/oauth2/oauth2-routing.module").then(
            (m) => m.Oauth2RoutingModule
          ),
      },
      {
        path: "approve",
        loadChildren: () => import("app/modules/admin/approve/approve.routes"),
      },
      {
        path: "sign-up",
        loadChildren: () => import("app/modules/auth/sign-up/sign-up.routes"),
      },
      {
        path: 'new-user',
        loadChildren: () =>
          import('app/modules/auth/auth-grant/new-user/new-user.routes'),
      },
      {
        path: "success",
        loadChildren: () =>
          import("app/modules/auth/auth-grant/auth-grant.routes"),
      },
      {
        path: "auth-grant",
        loadChildren: () =>
          import("app/modules/auth/auth-grant/auth-grant.routes"),
      },
    ],
  },

  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes"),
      },
    ],
  },

  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: "summary",
        data: { breadcrumb: { alias: "Overview" } },
        loadChildren: () => import("app/modules/admin/summary/summary.routes"),
      },
      {
        path: "overview",
        data: { breadcrumb: { alias: "Overview" } },
        loadChildren: () => import("app/modules/admin/overview/overview.routes"),
      },
      {
        path: "settings",
        data: { breadcrumb: { alias: "Settings" } },
        loadChildren: () => import("app/modules/admin/settings/settings.routes"),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "summary",
  },
];
