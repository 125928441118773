/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";
import cloneDeep from "lodash-es/cloneDeep";

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: "mssp",
    title: "Overview",
    type: "basic",
    icon: "mat_outline:view_compact",
    link: "/summary",
  },
  {
    id: "settings",
    title: "Settings",
    type: "basic",
    icon: "admin_panel_settings",
    link: "/settings",
  },
];

const compact: any = cloneDeep(defaultNavigation);
compact.map((x: any) => {
  if (x.children) {
    x.children = [];
    x.type = "aside";
  } else {
    x.type = "basic";
  }
});
export const compactNavigation: FuseNavigationItem[] = compact;

export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;

const horizontal: any = cloneDeep(defaultNavigation);
horizontal.map((x: any) => {
  x.children ? (x.children = []) : null;
});
export const horizontalNavigation: FuseNavigationItem[] = horizontal;
