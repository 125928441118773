import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  CommonModule,
  DatePipe,
  DOCUMENT,
  NgClass,
  NgFor,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import {
  filter,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  throwError,
} from "rxjs";
import { MaterialModule } from "../../../material.module";
import { CommonService } from "../../../_services/common.service";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { FuseDrawerComponent } from "../../../../@fuse/components/drawer";
import { SettingsViewComponent } from "./settings-view/settings-view.component";
import { SettingsComponent } from "../settings/settings.component";
import { UserService } from "../../../core/user/user.service";
import { FuseAlertComponent } from "@fuse/components/alert";
import { BaseRequestService } from "app/_services/base.service";
import { LoaderService } from "app/_services/loader.service";
import { MyToastrService } from "app/_services/toastr.service";
import { DynamicFormComponent } from "app/modules/shared/forms/dynamic-form/dynamic-form.component";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { HttpClient } from "@angular/common/http";
import { DynamicSettingsService } from "./dynamic-settings.service";
import { DirectivesModule } from "app/-directives/-directives.module";
import { GettingStartedComponent } from "app/modules/shared/getting-started/getting-started.component";
import jwt_decode from "jwt-decode";
import { environment } from "environments/environment";

@Component({
  selector: "dynamic-settings",
  templateUrl: "./dynamic-settings.component.html",
  styleUrls: ["./dynamic-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
  exportAs: "dynamicSettings",
  standalone: true,
  imports: [
    CommonModule,
    GettingStartedComponent,
    DirectivesModule,
    SettingsViewComponent,
    NgClass,
    NgIf,
    MatIconModule,
    MatButtonModule,
    FuseScrollbarDirective,
    NgFor,
    NgTemplateOutlet,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    DatePipe,
    MaterialModule,
    FuseAlertComponent,
    FuseDrawerComponent,
    NgOptimizedImage,
    SettingsComponent,
    DynamicFormComponent,
  ],
})
export class DynamicSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  domainName: any;
  payload: any;
  showMatrix: any = {
    jobs: ["asset", "scan", "attack-surface-mapper"],
  };
  help: any = {
    name: "Help",
    id: "help",
    icon: "mat_solid:help_outline",
    target: "help-link",
  };
  support: any = {
    name: "Support",
    id: "support",
    icon: "mat_solid:support",
    target: "support-link",
  };
  jobs: any = {
    name: "Jobs",
    id: "jobs",
    icon: "mat_outline:hourglass_top",
    target: "jobs",
    api: "jobs",
  };
  // dashboard: any = {
  //   name: "Dashboard",
  //   id: "dashboard",
  //   icon: "heroicons_outline:chart-bar-square",
  //   target: "dashboard",
  // };
  jobEnum: any = {
    scan: `type='PIISCAN'`,
    asset: `type!='PIISCAN' and type!='EXTERNALSCAN'`,
    "external-assets": `type='EXTERNALSCAN'`,
    "attack-surface-mapper": `type='ATTACKSURFACE'`,
  };
  // alerts: any = {
  //     name: 'Alerts',
  //     id: 'alerts',
  //     icon: 'heroicons_solid:bell-alert',
  //     target: 'alerts',
  //     api: 'alerts'
  // }
  jobCondition: any = "";
  isShowHyperLink = true;
  action: any;
  actions: any = [];
  allowedId: any = ["asset-time-line", "alerts"];
  selectedAction: any;
  opened: boolean = false;
  private _mutationObserver: MutationObserver;
  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild("dynamicSettingsDrawer")
  private _dynamicSettingsDrawer: FuseDrawerComponent;
  @ViewChild("detailView", { static: true }) detailView: ElementRef;
  openDS: Subscription;
  subs: Subscription;
  site: Subscription;
  sched: Subscription;
  nocomp: Subscription;
  dv: Subscription;
  ex: Subscription;
  pii: Subscription;
  initNewCompany = false;
  patchDetails: any = {
    is_eula_accepted: false,
    patching_status: false,
  };
  comDelConf: any = {
    showAction: false,
    formElements: [
      {
        name: "id",
        description: "Select Company",
        required: true,
        dValue: "dValue",
        dKey: "dKey",
        schema: {
          type: "dropdown",
          options: [],
        },
      },
    ],
    saveBtn: "Delete",
  };
  cmp: any = {};

  comConf: any = {
    showAction: false,
    spanLayout: 2,
    formElements: [
      {
        name: "name",
        description: "Enter Company Name",
        example: "Ex. Million Dollar Company",
        required: true,
        min: 3,
        max: 36,
        schema: {
          type: "text",
        },
      },
      {
        name: "description",
        description: "Enter Description",
        example: "Ex. Banking, Tampa Florida",
        required: true,
        schema: {
          type: "textarea",
        },
      },
      {
        name: "customer_name",
        description: "Customer Name",
        schema: {
          type: "text",
        },
      },
      {
        name: "adddress_city",
        description: "City",
        schema: {
          type: "text",
        },
      },
      {
        name: "adddress_state",
        description: "State",
        schema: {
          type: "text",
        },
      },
      {
        name: "adddress_country",
        description: "Country",
        schema: {
          type: "text",
        },
      },
      {
        name: "adddress_zipcode",
        description: "Zip Code",
        schema: {
          type: "number",
        },
      },
    ],
    closeBtn: "Cancel",
    saveBtn: "Update",
  };
  com: any = {};

  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _elementRef: ElementRef,
    private _ds: DynamicSettingsService,
    private _renderer2: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
    private uS: UserService,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    public cs: CommonService,
    private router: Router,
    private baseService: BaseRequestService,
    private toast: MyToastrService,
    private loaderService: LoaderService,
    public confirmDialog: FuseConfirmationService
  ) {
    router.events
      .pipe(
        filter(
          (event: any) =>
            event instanceof NavigationEnd || event instanceof Scroll
        )
      )
      .subscribe((val: any) => {
        const url = val.url || val.routerEvent.url;
        this.showActions(url);
      });
    this.dv = this.cs.dynamicScrollTop.subscribe((res) => {
      this.detailView.nativeElement.scrollTo(0, 0);
    });

    this.nocomp = this.cs.noCompanyEvent.subscribe((res: any) => {
      // this.initNewCompany = true;
      setTimeout(() => {
        this.selectAction({
          name: "New Company",
          id: "new-company",
          icon: "heroicons_outline:folder-plus",
          target: "new-company",
          api: "companies",
        });
      }, 5000);
    });

    this.site = this.cs.selectedSiteChanged.subscribe((res) => {
      const url = this.router.url;
      this.showActions(url);
    });
    this.ex = this._ds.externalTabEmitter.subscribe((res) => {
      this.showActions(res);
    });
    this.sched = this._ds.schedulerTabEmitter.subscribe((res) => {
      this.showActions(res);
    });
    this.pii = this._ds.partnerTabEmitter.subscribe((res) => {
      this.showActions(res);
    });
    this.subs = this.cs.dynamicSettingsToggle.subscribe((res) => {
      this._dynamicSettingsDrawer.close();
    });
    this.openDS = this.cs.openDiscoverySettings.subscribe((res: any) => {
      setTimeout(() => {
        this.selectAction({
          name: "Discovery Settings and Credentials",
          id: "discovery-settings",
          icon: "feather:list",
          target: "discovery-settings",
          api: "discovery_settings",
        });
      }, 2000);
    });
    this.cs.openSearchCVESettings
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        setTimeout(() => {
          const cve = this.actions.filter((x: any) => x.id === "search-cve");
          if (!cve || !cve.length) {
            this.actions.push({
              name: "Search CVE",
              id: "search-cve",
              icon: "mat_outline:search",
              target: "search-cve",
            });
          }
          this.selectAction({
            name: "Search CVE",
            id: "search-cve",
            icon: "mat_outline:search",
            target: "search-cve",
          });
        }, 2000);
      });
  }

  /**
   * Host binding for component classes
   */
  @HostBinding("class") get classList(): any {
    return {
      "dynamic-settings-opened": this.opened,
    };
  }

  ngAfterViewInit(): void {
    this._mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const mutationTarget = mutation.target as HTMLElement;
        if (mutation.attributeName === "class") {
          if (mutationTarget.classList.contains("cdk-global-scrollblock")) {
            const top = parseInt(mutationTarget.style.top, 10);
            this._renderer2.setStyle(
              this._elementRef.nativeElement,
              "margin-top",
              `${Math.abs(top)}px`
            );
          } else {
            this._renderer2.setStyle(
              this._elementRef.nativeElement,
              "margin-top",
              null
            );
          }
        }
      });
    });
    this._mutationObserver.observe(this._document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }

  captureScreen(): void {}

  initNewComp(): void {
    if (!this.actions || !this.actions.length) {
      setTimeout(() => this.initNewComp(), 2000);
      return;
    }
    setTimeout(() => {
      const comp = this.actions.map((x: any) => x.id === "new-company");
      if (comp && comp.length) {
        this.selectAction(comp[0]);
      }
    }, 1000);
  }

  closeDrawer(): void {
    this._dynamicSettingsDrawer.close();
    this.selectedAction = undefined;
    this.opened = false;
    this._mutationObserver.disconnect();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Disconnect the mutation observer
    this._mutationObserver.disconnect();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
    this.site.unsubscribe();
    this.sched.unsubscribe();
    this.nocomp.unsubscribe();
    this.dv.unsubscribe();
    this.openDS.unsubscribe();
    this.ex.unsubscribe();
    this.pii.unsubscribe();
  }

  showActions(url: any): void {
    const router = url.split("/");
    let id = router[router.length - 1].split("?")[0];
    if (!isNaN(Number(id))) {
      id = "company";
    }
    this.jobCondition = router.length > 0 ? this.jobEnum[router[1]] : "";
    this.isShowHyperLink =
      id.indexOf("external") !== -1 ||
      id.indexOf("attack-surface-mapper") !== -1
        ? false
        : true;
    this._ds.getSettingsById(id).subscribe((result) => {
      // Mark for check
      if (this.showMatrix.jobs.indexOf(router[1]) > -1) {
        result.push(this.jobs);
      }
      //   result.push(this.alerts);
      // result.push(this.dashboard);
      if (this.cs.currentScope === "*") {
        this.actions = result.filter(
          (setting: any) =>
            ![
              "suppress_vulnerability",
              "createexception",
              "upload_evidence",
            ].includes(setting.api)
        );
      } else {
        this.actions = result;
      }
      this._changeDetectorRef.detectChanges();
      if (this.initNewCompany) {
        this.initNewComp();
      }
    });
  }

  ngOnInit(): void {
    this.uS.userd$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: any) => {
        this.domainName = user["urn:zitadel:iam:user:resourceowner:name"];
        let s = Object.keys(user["urn:zitadel:iam:org:project:roles"]);
        let tenantid = Object.keys(
          user["urn:zitadel:iam:org:project:roles"][s[0]]
        )[0];
        this.payload = {
          sub: tenantid,
          email: user["email"],
          first_name: user["given_name"],
          last_name: user["family_name"],
          exp: Math.floor((Date.now() + 120 * 60 * 1000) / 1000),
        };
        this._changeDetectorRef.markForCheck();
        var token = localStorage.getItem("zitadel:id_token");
        this.uS.userd$ = of(jwt_decode(token));
        this.uS._user_roles = Object.keys(
          jwt_decode(token)["urn:zitadel:iam:org:project:roles"]
        );
      });
    if (!this.actions.length) {
      const url = this.router.url;
      this.showActions(url);
    }
  }

  /**
   * Open the panel
   */
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Select the chat
   *
   * @param action
   */
  selectAction(action: any): void {
    if (action.target === "support-link") {
      this.cs.captureScreenChange.next({});
    }
    if (action.emtterAction) {
      // @ts-ignore
      this._ds[action.emtterAction].next(action);
      return;
    }
    if (
      action.target === "asset-time-line" ||
      action.target === "asset_remediated_record"
    ) {
      if (
        this.cs.selectedTableRows &&
        !this.cs.selectedTableRows.length &&
        !this.cs.selctedAsset
      ) {
        this.toast.sToast("info", "Please click IP to select one asset.");
        return;
      }
      if (this.cs.selectedTableRows && this.cs.selectedTableRows.length > 1) {
        this.toast.sToast("info", "Please select one asset only.");
        return;
      }
    }

    if (action.target === "asset_problem_suppress_record") {
      if (
        this.cs.selectedTableRows &&
        !this.cs.selectedTableRows.length &&
        !this.cs.selctedAsset
      ) {
        this.toast.sToast("info", "Please select one asset.");
        return;
      }
      if (this.cs.selectedTableRows && this.cs.selectedTableRows.length > 1) {
        this.toast.sToast("info", "Please select one asset only.");
        return;
      }
    }
    // Open the panel
    if (action.target === "delete-company") {
      this.getCompanies();
    }
    if (action.target === "update-company") {
      const condition: any = {
        condition: true,
        skip: 0,
        limit: 1,
        order_by: "created desc",
      };

      if (this.cs.currentScope !== "*") {
        condition.condition = "id=" + this.cs.currentScope.id + "";
      }
      this.baseService
        .doRequest("/r/company/companies", "get", null, condition)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          if (res.status && res.data) {
            this.comConf.elementList = this.cs.processFormElements(
              this.comConf.formElements
            );
            this.com = res.data[0];
          }
        });
    }
    if (action.target === 'profile') {
        window.open(`${environment.authURL}/ui/console/users/me`);
        return;
    }
    // if (action.target === 'user-management') {
    //     window.open('https://authz.myconnectsecure.com/ui/console/users');
    //     return;
    // }
    if (action.target === "dashboard") {
      const token = this.cs.generateToken(this.payload);
      this.loaderService.display(true);
      this.baseService
        .doRequest(`/r/user/set_user_attribute`, "get")
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.loaderService.display(false);
          if (res.status) {
            window.open(res.message, "_blank");
          } else {
            this.toast.sToast("error", res.message);
          }
        });
      return;
    }
    if (action.target === "reports") {
      window.open(
        `https://reportcs.myconnectsecure.com/reporting/site/${this.domainName}`
      );
      return;
    }
    this.selectedAction = null;
    this.selectedAction = action;
    this._toggleOpened(true);
    this.cs.dynamicScrollTop.next({});
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement("div");

    // Return if overlay couldn't be create for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add("dynamic-settings-overlay");

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(
      this._elementRef.nativeElement.parentElement,
      this._overlay
    );

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener("click", () => {
      this.close();
    });
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    if (
      this.selectedAction &&
      this.selectedAction.isActionOnly &&
      this.selectedAction.target === "vulnerability_suppress"
    ) {
      this.cs.openSuppress.next("");
    }
    if (
      this.selectedAction &&
      this.selectedAction.isActionOnly &&
      this.selectedAction.target === "problem_suppress"
    ) {
      this.cs.problemSuppress.next("");
    }
    if (
      this.selectedAction &&
      this.selectedAction.isActionOnly &&
      this.selectedAction.target === "suppress"
    ) {
      this.cs.remediationSuppress.next("");
    }
    if (
      this.selectedAction &&
      this.selectedAction.isActionOnly &&
      this.selectedAction.target === "upload-evidence"
    ) {
      this.cs.uploadEvidence.next("");
    }
    if (
      !this._dynamicSettingsDrawer.opened &&
      (!this.selectedAction || !this.selectedAction.isActionOnly)
    ) {
      this._dynamicSettingsDrawer.toggle();
    }

    /*// If the panel opens, show the overlay
        if (open) {
            this._showOverlay();
        }
        // Otherwise, hide the overlay
        else {
            this._hideOverlay();
        }*/
  }

  getCompanies(): void {
    let condition: any = {
      condition: true,
      skip: 0,
      limit: 500,
      order_by: "created desc",
    };
    this.baseService
      .doRequest("/r/company/companies", "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          var listdisc = [];
          result.data.forEach((ele: any) => {
            var temp = { dKey: `${ele.name}`, dValue: ele.id };
            listdisc.push(temp);
          });
          this.comDelConf.elementList = this.cs.processFormElements(
            this.comDelConf.formElements
          );
          this.comDelConf.elementList[0].options = listdisc;
          this._changeDetectorRef.detectChanges();
        } else {
          this.toast.sToast("error", result.message);
        }
      });
  }

  deleteCompany($event: any): void {
    const confirmation = this.confirmDialog.open({
      title: "Confirmation",
      message: `Are you sure you want to delete the selected company ? If you delete the company, all the data pertaining to the company including assets, agents, vulnerability data, remediation data, reports, PII scan result data, etc., will be permanently deleted.`,
      actions: {
        confirm: {
          label: "Delete",
        },
      },
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === "confirmed") {
        this.loaderService.display(true);
        this.baseService
          .doRequest(`/d/company/companies/${$event.id}`, "delete")
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result.status) {
              this.toast.sToast("success", "Removed successfully");
              this._dynamicSettingsDrawer.close();
              this.cs.newCompanyCall.emit({});
            } else {
              this.toast.sToast("error", result.message);
            }
          });
      }
    });
  }

  saveCompany($event: any) {
    this.loaderService.display(true);
    const reqData: any = {
      data: $event,
      id: parseInt($event.id),
    };
    delete reqData.data.id;
    this.baseService
      .doRequest("/w/company/companies", "patch", reqData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result.status) {
          this.toast.sToast(
            "success",
            `Company ${$event.name} updated successfully!`
          );
          localStorage.setItem("cmp", $event.name);
          this.cs.newCompanyCall.emit({});
          this._dynamicSettingsDrawer.toggle();
          this._changeDetectorRef.markForCheck();
        } else {
          this.toast.sToast("error", result.message);
        }
      });
  }
  onOpenedChange($event: any): void {
    if (!$event) {
        this.closeDrawer()
    }
}
}
