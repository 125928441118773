<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
    [navigation]="navigation.default" [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img class="max-w-[12rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo" aria-label="dark"
          alt="Logo image">
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div #_wrapper_ id="_wrapper_" class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">
  <!-- Header -->
  <div class="relative flex justify-center w-full overflow-hidden z-49 bg-primary-700 print:hidden">
    <div
      class="max-w-full w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card">
      <!-- Top bar -->
      <div class="relative flex flex-auto flex-0 items-center h-16 px-4 md:px-6">
        <!-- Logo -->
        <ng-container *ngIf="!isScreenSmall">
          <div class="flex items-center mx-2">
            <!-- Light version -->
            <img class="max-w-[12rem] h-[2.5rem] object-contain dark:hidden" [src]="_bs.whitelabel().logo"
              aria-label="light" alt="Logo image">
            <!-- Dark version -->
            <img class="hidden dark:flex max-w-[12rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo_dark"
              aria-label="dark" alt="Logo image">
          </div>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
          <!-- <languages></languages> -->
          <!-- <app-add-partners></app-add-partners> -->
          <!-- <languages></languages> -->
          <!-- <settings></settings> -->
          <app-theme-switch class="hidden md:block"></app-theme-switch>
          <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
          <!-- <search [appearance]="'bar'"></search> -->
          <!--<new-company></new-company>-->
          <scope></scope>
          <button class="lg:hidden" mat-icon-button (click)="dynamicSettings.toggle()">
            <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
          </button>
          <!--<messages></messages>-->
          <!--<notifications></notifications>-->
          <user></user>
        </div>
      </div>
      <!-- Bottom bar -->
      <ng-container *ngIf="!isScreenSmall">
        <div class="relative flex flex-auto flex-0 items-center h-16 px-4">
          <fuse-horizontal-navigation [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </div>
      </ng-container>
    </div>
  </div>

  <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
  <!-- Content -->
  <div class="flex flex-auto justify-center w-full sm:px-8">
    <div class="flex flex-col flex-auto w-full sm:max-w-full sm:shadow-xl sm:overflow-hidden bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
               Otherwise, layout changes won't be registered and the view won't be updated! -->
      <!--<router-outlet *ngIf="true"></router-outlet>-->
      <router-outlet>

      </router-outlet>
      <!-- <app-skeleton *ngIf="_cs.isComLoading">

          </app-skeleton> -->
    </div>
  </div>

  <!-- Footer -->
  <div class="relative flex justify-center w-full z-49 print:hidden">
    <div
      class="flex items-center max-w-full w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
      <span class="font-medium text-secondary">ConnectSecure &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>
<!-- <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings> -->