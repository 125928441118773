// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseURL: 'https://msspportalstaging.myconnectsecure.com',
    configURL: 'https://configuration.myconnectsecure.com/api/v4/configuration',
    configHost: 'https://configuration.myconnectsecure.com',
    authURL: 'https://authz.myconnectsecure.com',
    authHost: 'authz.myconnectsecure.com',
    isFqdn: false,
    logo_dark: '/assets/images/logo/cs_logo_dark.png',
    logo: '/assets/images/logo/cs_logo_light.png',
    icon: '/assets/images/logo/cs_logo_only.png',
    icon_dark: '/assets/images/logo/White_Turtle.png',
    productName: 'ConnectSecure',
    tenantURL: 'https://test.myconnectsecure.com/sign-in',
    istest: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
