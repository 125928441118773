<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-card dark:bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center mx-2 lg:mr-8 mt-2">
            <div class="hidden lg:flex">
                <!-- Light version -->
                <img class="dark:hidden  max-w-[12rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo"
                    aria-label="light" alt="Logo image">
                <!-- Dark version -->
                <img class="hidden dark:flex  max-w-[12rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo_dark" aria-label="dark" alt="Logo image">
                <!--<div class="flex flex-row items-end justify-end">
                <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            </div>-->
            </div>
            <!-- Small version -->

            <div class="flex lg:hidden">
                <!-- Light version -->
                <img class="dark:hidden  max-w-[10rem] h-[2.5rem] object-contain" [src]="_bs.whitelabel().logo"
                    aria-label="light" alt="Logo image">
                <!-- Dark version -->
                <img class="hidden dark:flex  max-w-[10rem] h-[2.5rem] object-contain"
                    [src]="_bs.whitelabel().logo_dark" aria-label="dark" alt="Logo image">
                <!--<div class="flex flex-row items-end justify-end">
                <mat-icon matTooltip="Change Logo" [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            </div>-->
            </div>

        </div>
        <!-- <div class="flex items-center justify-center h-20 p-2 mt-2">
            <img
                class="w-26 dark:hidden"
                [src]="_bs.whitelabel().logo" aria-label="logo"
                alt="Logo image">
            <img
                class="w-26 hidden dark:flex"
                [src]="_bs.whitelabel().logo_dark" aria-label="logo"
                alt="Logo image">
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- <scope *ngIf="_cs.isChecked === 'left'"></scope> -->
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <settings></settings> -->
            <!-- <languages></languages> -->
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
            <app-theme-switch class="hidden md:block"></app-theme-switch>
            <!-- <search [appearance]="'bar'"></search> -->
            <!--<new-company></new-company>-->
            <!-- <scope *ngIf="_cs.isChecked === 'right'"></scope> -->
            <!-- <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="dynamicSettings.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
                    </button> -->
            <!--<messages></messages>-->
            <!--<notifications></notifications>-->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <!--<router-outlet *ngIf="true"></router-outlet>-->
        <router-outlet>

        </router-outlet>
        <!-- <app-skeleton *ngIf="_cs.isComLoading">
    
        </app-skeleton> -->
    </div>

    <!-- Footer -->
    <div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">ConnectSecure &copy; {{currentYear}}</span>
    </div>

</div>


<!-- <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings> -->