<router-outlet>
    <div *ngIf="(loading$ | async) && change !== 'yes'" class="loader-class custom-class">
        <img src="/assets/images/loading.gif" alt="" class=" w-6 mb-2">
        <p class="relative" *ngIf="!contentText || contentText === ''">Loading... </p>
        <p class="relative break-words px-4">{{contentText}}</p>
    </div>

    <fuse-splash-screen *ngIf="change === 'yes'">
        <img class="w-[12rem]" src="/assets/images/logo/cs_logo_dark.png" aria-label="logo" alt="logo">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </fuse-splash-screen>
</router-outlet>
