<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-card dark:bg-gray-900 print:hidden" [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.compact"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20 p-2">
      <img class="w-50 dark:hidden" [src]="_bs.whitelabel().icon" aria-label="logo" alt="Logo image" />
      <img class="w-50 hidden dark:flex" [src]="_bs.whitelabel().icon_dark" aria-label="logo" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <app-add-partners></app-add-partners> -->
      <!-- <languages></languages> -->
      <!-- <settings></settings> -->
      <app-theme-switch class="hidden md:block"></app-theme-switch>
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
      <!-- <search [appearance]="'bar'"></search> -->

      <!--<new-company></new-company>-->
      <button class="lg:hidden" mat-icon-button (click)="dynamicSettings.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
      </button>
      <scope></scope>
      <!--<messages></messages>-->
      <!--<notifications></notifications>-->
      <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>-->
      <user></user>
    </div>
  </div>
  <dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
         Otherwise, layout changes won't be registered and the view won't be updated! -->
    <!--<router-outlet *ngIf="true"></router-outlet>-->
    <router-outlet>

    </router-outlet>
    <!-- <app-skeleton *ngIf="_cs.isComLoading">

    </app-skeleton> -->
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">ConnectSecure &copy; {{ currentYear }}</span>
  </div>
</div>