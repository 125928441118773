<div class="flex flex-col flex-auto pt-4 -mx-4">
  <mat-form-field class="mini-form-field fuse-mat-no-subscript fuse-mat-rounded mb-4 w-[25vw]">
      <mat-select #companySelect id="sSearchCompanyInput" class="mat-small w-full round-select" placeholder=""
          (openedChange)="closeCurrentCompany($event)" [formControl]="companyCtrl"
          (selectionChange)="updateCurrentCompany($event.value)">
          <mat-option>
              <ngx-mat-select-search [formControl]="msspFilterCtrl" [placeholderLabel]="searchTxt"
                  [noEntriesFoundLabel]="'No matching company found'" [searching]="searching"
                  [enableClearOnEscapePressed]="true">
              </ngx-mat-select-search>
          </mat-option>
          <mat-option class="text-medium font-bold" [value]="'*'">Global</mat-option>
          <mat-option [matTooltip]="company.name" [matTooltipPosition]="'left'"
              *ngFor="let company of companies | search: msspFilterCtrl.value" [id]="company.tenant_name"
              [value]="company.tenant_id">
              <span> {{company.tenant_name }}</span>
          </mat-option>
      </mat-select>
  </mat-form-field>
</div>
