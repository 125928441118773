import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  DatePipe,
  DecimalPipe,
  NgClass,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  Scroll,
} from "@angular/router";
import { ScopeService } from "app/layout/common/scope/scope.service";
import { Scope } from "app/layout/common/scope/scope.types";
import {
  BehaviorSubject,
  debounceTime,
  filter,
  interval,
  ReplaySubject,
  Subject,
  Subscription,
  takeUntil,
} from "rxjs";
import { CommonService } from "../../../_services/common.service";
import { MaterialModule } from "../../../material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { AppFilterPipeModule } from "../../../_filters/app.filter-pipe.module";
import { BaseRequestService } from "../../../_services/base.service";
import { MatSelect } from "@angular/material/select";
import { DynamicFormComponent } from "../../../modules/shared/forms/dynamic-form/dynamic-form.component";
import { LoaderService } from "../../../_services/loader.service";
import { MyToastrService } from "../../../_services/toastr.service";
import { UserService } from "app/core/user/user.service";

@Component({
  selector: "scope",
  templateUrl: "./scope.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "scope",
  standalone: true,
  imports: [
    MatButtonModule,
    NgIf,
    MatIconModule,
    MatTooltipModule,
    NgFor,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    DatePipe,
    MaterialModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    DecimalPipe,
    AppFilterPipeModule,
    DynamicFormComponent,
  ],
})
export class ScopeComponent implements OnInit, OnDestroy {
  @ViewChild("companySelect", { static: true }) companySelect!: MatSelect;
  @ViewChild("scopeOrigin") private _scopeOrigin: MatButton;
  @ViewChild("scopePanel") private _scopePanel: TemplateRef<any>;
  @ViewChild("companyPanel") private _companyPanel: TemplateRef<any>;
  @Input() isLocal = false;
  @Output() updateCompany = new EventEmitter();
  protected onDestroySearch = new Subject<void>();
  scope: Scope[];
  distributor_id: any;
  company: any;
  public companySourceHash: any = {};
  cScope: any;
  isSearchK = false;
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public companyCtrl: FormControl = new FormControl();
  public msspFilterCtrl: FormControl = new FormControl();
  public companySourceFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public companies: any = [];
  public sourceCompanies: any = [];
  public allMssps: any = [];
  public allSourceComp: any = [];
  totalMssp: any;
  selectedCompany: any;
  companyElements: any = [];
  formElements: any = [
    {
      name: "name",
      description: "Enter Distributor Name",
      example: "Ex. Million Dollar Company",
      required: true,
      min: 3,
      max: 36,
      schema: {
        type: "text",
      },
    },
    {
      name: "description",
      description: "Enter Description",
      example: "Ex. Banking, Tampa Florida",
      required: false,
      schema: {
        type: "textarea",
      },
    },
  ];
  updateCmp: Subscription;
  subs: Subscription;
  currentSource: "azure" | "ad" | "" = "";
  showCom = true;
  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _scopeService: ScopeService,
    private route: ActivatedRoute,
    private _overlay: Overlay,
    private toast: MyToastrService,
    private _viewContainerRef: ViewContainerRef,
    public cs: CommonService,
    private loaderService: LoaderService,
    private _baseRequestService: BaseRequestService,
    private _router: Router,
    public _us: UserService
  ) {
    this.subs = this.cs.selectedSiteChanged.subscribe((res) => {
      this.cScope = res;
      if (
        !this.companies.filter((x) => x.tenant_id === res) ||
        !this.companies.filter((x) => x.tenant_id === res).length
      ) {
        if (res !== "*") {
          this.companies.push({ tenant_id: res, tenant_name: this.cs.companyHash[res].tenant_name });
        }
      }
      this._changeDetectorRef.markForCheck();
    });
    this.updateCmp = this.cs.updateCompanyCall.subscribe((res) => {
      this.companyCtrl.setValue(res);
      this.updateCurrentCompany(res);
    });
    this.cs.newCompanyCall
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.getMssps();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._scopeService.scope$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((scope: Scope[]) => {
        // Load the scope
        this.scope = scope;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
    this.getMssps();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.subs.unsubscribe();
    this.updateCmp.unsubscribe();
    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  public searching = false;
  searchTxt: any = "Search tenant";

  closeCurrentCompany($event: boolean) {
    this.searching = false;
    if (this.allMssps) {
      this.companies = this.allMssps.slice();
      this.filteredCompanies.next(this.allMssps.slice());
      this._changeDetectorRef.markForCheck();
    }
    if (!$event && !this.selectedCompany) {
      this.getMssps();
    }
  }

  updateCurrentCompany(event: any): void {
    const router = this._router.url.split("/");
    this.selectedCompany = event === "*" ? event : this.cs.companyHash[event].tenant_name;
    this.cs.isComLoading = false;
    localStorage.setItem("cmp", event === "*" ? event : this.cs.companyHash[event].tenant_name);
    this.cs.currentScope = event === "*" ? event : this.cs.companyHash[event];
    this.cs.selectedSiteChanged.next(event);
  }

  getMssps(search?: string): void {
    if (
      !this._baseRequestService.user() ||
      !this._baseRequestService.user().email
    ) {
      setTimeout(() => {
        this.getMssps(search);
      }, 1000);
      return;
    }
    this.searching = true;
    this.cs.isComLoading = true;
    const condition = search
      ? { condition: `mssp_request_status = 'Approved' and tenant_name ilike '%${search}%'` }
      : {
        condition: `mssp_request_status = 'Approved'`,
        skip: 0,
        limit: 100,
        order_by: "tenant_name asc",
      };
    this._baseRequestService
      .doRequest("/r/report_queries/list_msp", "get", null, condition)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        if (result.status) {
          this.totalMssp = result.data.length;
          if (result.data.length === 0) {
            this.companyCtrl.setValue("*");
            this.updateCurrentCompany("*");
            return;
          }
          result.data.forEach((c: any) => {
            c.tenant_id = c.tenant_id + "";
            this.cs.companyHash[c.tenant_id] = c;
          });
          if (!search) {
            this.allMssps = result.data;
            this.cs.allMssps = result.data;
          } else {
            this.isSearchK = true;
            this.allMssps = Array.from(
              new Map(
                [...this.allMssps, ...result.data].map((obj) => [obj.tenant_id, obj])
              ).values()
            );
          }

          this.companies = result.data.slice();
          this._changeDetectorRef.markForCheck();
          this.searching = false;
          const cmp = localStorage.getItem("cmp");
          if (cmp && cmp !== "*") {
            const company = this.companies.filter((x: any) => x.tenant_name === cmp);
            if (company.length) {
              this.companyCtrl.setValue(company[0].tenant_id);
              this.updateCurrentCompany(company[0].tenant_id);
            } else {
              this.companyCtrl.setValue(this.companies[0].tenant_id);
              this.updateCurrentCompany(this.companies[0].tenant_id);
            }
          }
          if (!cmp || cmp === "*") {
            this.companyCtrl.setValue("*");
            this.updateCurrentCompany("*");
          }

        }
      });
  }

}
