import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { settings as settingsData } from 'app/mock-api/dynamic-settings/data';
import assign from 'lodash-es/assign';
import cloneDeep from 'lodash-es/cloneDeep';
import {of} from "rxjs";
import jwt_decode from "jwt-decode";
import { UserService } from 'app/core/user/user.service';

@Injectable({providedIn: 'root'})
export class SettingsMockApi
{
    private _settings: any = settingsData;
    settings: any = settingsData;
    roleMapping: any = {
        activedirectoryviewer: ['company'],
        activedirectorywriter: ['company', 'azure', 'microsoft', ],
        assetviewer: ['company'],
        assetwriter: ['company', 'external-assets-config', 'application-baseline', 'external-assets-profile',
            'external-assets','assets', 'deprecated-assets', 'problems', 'attack-surface-mapper' ],
        complianceviewer: ['company'],
        compliancewriter: ['company', 'compliance-standards'],
        piireader: ['company'],
        piiwriter : ['company', 'pii-scan-profile', 'pii-scan-result', 'remediation-plan' ],
        vulnerabilityviewer: ['company' ],
        vulnerabilitywriter: ['company', 'vulnerabilities']
    }
    objectKeys = Object.keys;
    currentRoles: any = [];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private uS: UserService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ settings - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/settings')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the settings
                const settings = cloneDeep(this._settings);

                // Find the settings
                let setting = [];

                let result = settings.find(item => item.id === id);

                if (result) {
                    setting = result.settings;
                }

                // Return the response
                return [200, setting];
            });
    }
}
