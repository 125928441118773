<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user?.avatar"
      [src]="user?.avatar"
    />
    <mat-icon
      *ngIf="!showAvatar || !user?.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
    <!-- <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span> -->
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
    </span>
  </button>
  <!-- <mat-divider class="my-2"></mat-divider> -->
  <!-- <button mat-menu-item (click)="viewAuthPage()">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button> -->
  <!--<button mat-menu-item (click)="viewUserManagementPage()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>User Management</span>
    </button>-->
  <!-- <button mat-menu-item (click)="viewReleaseNotes()">
        <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon>
        <span>Release Notes</span>
    </button>
    <button mat-menu-item (click)="viewBuildInfo()">
        <mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
        <span>Build Info</span>
    </button> -->
  <!--<button mat-menu-item (click)="viewReportPage()">
        <mat-icon [svgIcon]="'heroicons_outline:presentation-chart-bar'"></mat-icon>
        <span>Reports</span>
    </button>-->
  <!--<button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>-->
  <!--  <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon
      [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
    ></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>

<!-- <mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu> -->

<s-modal id="releaseNotes">
  <div
    class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row"
    *ngIf="releaseNotes"
  >
    <div
      class="w-1/3 h-[80vh] max-w-md rounded-l-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4"
      fuseScrollbar
    >
      <p class="mb-4 font-bold text-xl tracking-wider">
        {{ releaseNotes.title }}
      </p>
      <span
        class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-xs font-bold me-2 px-2.5 py-1 rounded-full"
        >{{ releaseNotes.type }}</span
      >
      <span class="text-sm font-bold tracking-wider uppercase">{{
        releaseNotes.date
      }}</span>
      <div class="bg-blue-600/80 rounded-2xl p-3 mt-4">
        <p class="mb-3 text-sm">New Features, Enhancements and Bug Fixes.</p>
      </div>
      <img
        src="/assets/images/releasenotes.png"
        alt="Release Notes"
        width="200px"
        class="mt-10"
      />
    </div>
    <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
      <button
        class="absolute right-2 z-10 mini-form-field"
        mat-icon-button
        aria-label="close modal icon"
        matTooltip="Close"
        (click)="_modalService.close('releaseNotes')"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div
        class="col-span-12 space-y-12 relative px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700"
      >
        <ng-container *ngFor="let sec of releaseNotes.sections">
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-violet-400"
          >
            <h3 class="text-xl font-semibold tracking-wide">{{ sec.title }}</h3>
            <div
              class="flex flex-row items-start mt-3 gap-2"
              *ngFor="let des of sec.description"
            >
              <i class="fas fa-check-circle mt-1 text-green-500"></i>
              <p class="ml-2">{{ des }}</p>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        class="flex items-center mt-4 py-4 pr-4 pl-1 sm:pr-12 sm:pl-7 border-t"
      >
        <button
          class="ml-auto px-3 py-2 text-xs font-medium text-center inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border border-blue-500 hover:border-transparent rounded"
        >
          View All Release Notes
        </button>
        <button
          (click)="_modalService.close('releaseNotes')"
          class="ml-2 px-3 py-2 text-xs font-medium text-center inline-flex items-center bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white border border-blue-500 hover:border-transparent rounded"
        >
          Got it
        </button>
      </div>
    </div>
  </div>
</s-modal>

<s-modal id="buildInformation">
  <div
    class="mx-auto w-full flex max-w-lg flex-col md:max-w-none md:flex-row"
    *ngIf="buildInfo && buildInfo.agent"
  >
    <div
      class="w-1/3 h-[80vh] max-w-md rounded-l-3xl bg-gradient-to-t from-blue-700 via-blue-700 to-blue-600 px-4 py-6 text-white sm:px-4"
      fuseScrollbar
    >
      <p class="mb-4 font-bold text-xl tracking-wider">Build Information</p>
      Agent Version:
      <span
        class="bg-gradient-to-r from-indigo-400 to-cyan-400 text-black text-md font-bold me-2 px-2.5 py-1 rounded-full"
        >{{ buildInfo.agent.agentVersion }}</span
      >
      <img
        src="/assets/images/buildinfo.svg"
        alt="Release Notes"
        width="200px"
        class="mt-10"
      />
    </div>
    <div class="w-2/3 h-[80vh] rounded-r-3xl bg-card px-4 py-4" fuseScrollbar>
      <button
        class="absolute right-2 z-10 mini-form-field"
        mat-icon-button
        aria-label="close modal icon"
        matTooltip="Close"
        (click)="_modalService.close('buildInformation')"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div
        class="col-span-12 space-y-12 relative px-4 ml-5 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-500 before:dark:bg-gray-700"
      >
        <ng-container
          *ngFor="let row of Objectkeys(buildInfo.agent.agentBuildInfo)"
        >
          <div
            class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-blue-400"
          >
            <h3 class="text-xl font-semibold tracking-wide">{{ row }}</h3>
            <div class="flex flex-col my-2">
              <div class="my-2 border-b pb-2">
                <div class="flex flex-row items-center">
                  <span
                    class="w-3 h-3 border-2 border-blue-500 rounded-full mr-1"
                  ></span>
                  <div class="text-md font-bold">MD5</div>
                </div>
                <div class="text-base font-mono break-all">
                  {{ buildInfo.agent.agentBuildInfo[row]["MD5"] }}
                </div>
              </div>
              <div class="my-2 border-b pb-2">
                <div class="flex flex-row items-center">
                  <span
                    class="w-3 h-3 border-2 border-green-500 rounded-full mr-1"
                  ></span>
                  <div class="text-md font-bold">SHA256</div>
                </div>
                <div class="text-base font-mono break-all">
                  {{ buildInfo.agent.agentBuildInfo[row]["SHA256"] }}
                </div>
              </div>
              <div class="my-2 border-b pb-2">
                <div class="flex flex-row items-center">
                  <span
                    class="w-3 h-3 border-2 border-orange-500 rounded-full mr-1"
                  ></span>
                  <div class="text-md font-bold">File Name</div>
                </div>
                <div class="text-base font-mono break-all">
                  {{ buildInfo.agent.agentBuildInfo[row]["filename"] }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</s-modal>
