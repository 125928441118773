export const settings = [
  {
    id: "app-all-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "allPartners",
      },
      // {
      //   name: "Tenant User Details",
      //   text: "Tenant User Details",
      //   id: "tenant-user-details",
      //   icon: "heroicons_mini:user-group",
      //   target: "tenant-user-details",
      //   emtterAction: "allPartners",
      // },
      // {
      //   name: "Change Distributor",
      //   text: "Change Distributor",
      //   id: "change-distributor",
      //   icon: "mat_outline:file_copy",
      //   target: "change-distributor",
      //   emtterAction: "allPartners",
      // },
      // {
      //   name: "Partner Stats",
      //   text: "Partner Stats",
      //   id: "partner-stats",
      //   icon: "mat_outline:info",
      //   target: "partner-stats",
      //   emtterAction: "allPartners",
      // },
    ],
    allowed: ["writer"],
  },
  {
    id: "app-trial-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "trailPartners",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "app-plan-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "planPartners",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "app-paid-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "paidPartners",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "app-suspended-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "suspendPartners",
      },
    ],
    allowed: ["writer"],
  },
  {
    id: "app-cancelled-partners",
    settings: [
      {
        name: "Check Email",
        text: "Check Email",
        id: "check-email",
        icon: "mat_outline:email",
        target: "check-email",
        emtterAction: "cancelledPartners",
      },
    ],
    allowed: ["writer"],
  },
];
//
